@use '@ingka/variables/style.scss' as variables;

.breadcrumbs {
    display: flex;
    margin: variables.$space-75 0;
    align-items: center;
    font-size: variables.$font-size-50;
    height: variables.$font-size-200;
}

.crumb {
    list-style: none;
    &:not(:last-child)::after {
        content: '›';
        font-size: variables.$font-size-100;
        line-height: variables.$font-size-100;
        margin: 0 variables.$space-50;
    }
    & > a {
        text-decoration: none;
    }
    & > a:hover {
        text-decoration: underline;
    }
}

.listName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-word;
}
